import {
    USER_LOGOUT,
    USER_LOGIN
} from './actions';
import { isJson } from '../../../constants';

const user = isJson(localStorage.getItem('user'));
const token = localStorage.getItem('token');
const initState = {
    isLoggedIn: !!token,
    user: user || {},
    token
};

const authReducer = (state = initState, action) => {
    if (action.type === USER_LOGOUT) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        return {
            ...state,
            user: {},
            isLoggedIn: false
        };
    } else if (action.type === USER_LOGIN) {
        return {
            ...state,
            user: action.user,
            isLoggedIn: true,
            token: action.token
        };
    }

    return state;
};

export default authReducer;
