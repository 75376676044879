import React, { useState, useEffect, Suspense, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    Toolbar,
    Tooltip
} from '@material-ui/core';
import {
    AppSidebar
} from '@coreui/react';
import {
    RefreshOutlined, SearchOutlined, ArrowDropDown, ArrowDropUp
} from '@material-ui/icons';
import {
    notification
} from 'antd';
import {
    Accordion,
    Button,
    Form,
    Collapse
} from 'react-bootstrap';

import {
    incrementIngredientPopularityService
} from '../../../Services/ingredients';

import {
    getAllCategoryIngredientsAction,
    getAllStylesAction,
    SET_INGREDIENTS,
    SET_STYLES
} from '../store/actions';

import style from './style';
import './styles.css';
import { isBase64, isJson, getQueryParams } from '../../../constants';


const NavBar = ({ history = {} }) => {

    const { location: { search = '' } = {} } = history;
    const { ingredients: ings } = getQueryParams(search);
    const [initData, setInitData] = useState(false);
    const [openCategory, setOpenCategory] = useState('');
    const [ingredients, setIngredients] = useState([]);
    const [styles, setStyles] = useState([]);
    const [popularIngredients, setPopularIngredients] = useState([]);
    const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch();

    const appState = useSelector((state) => state.app);

    const addOrRemoveIngredient = (obj, all = false) => ({ target: { checked } }) => {
        if (checked) {
            dispatch({
                type: SET_INGREDIENTS,
                ingredients: [...appState.selectedIngredients, obj]
            });
            incrementIngredientPopularity(obj.id);

        } else {
            dispatch({
                type: SET_INGREDIENTS,
                ingredients: appState.selectedIngredients.filter(ing => ing.id !== obj.id)
            });
        }
    };
    const addOrRemoveAllIngredient = () => ({ target: { checked } }) => {
        if (checked) {
            let selectedIngredients = [];
            ingredients.map(category => {
                selectedIngredients = selectedIngredients.concat(category.ingredients);
            })
            return dispatch({
                type: SET_INGREDIENTS,
                ingredients: selectedIngredients
            });
        } else {
            return dispatch({
                type: SET_INGREDIENTS,
                ingredients: []
            });
        }
    };

    const addOrRemoveStyle = (obj) => ({ target: { checked } }) => {
        if (checked) {
            dispatch({
                type: SET_STYLES,
                styles: [...appState.selectedStyles, obj]
            });
        } else {
            dispatch({
                type: SET_STYLES,
                styles: appState.selectedStyles.filter(ing => ing.id !== obj.id)
            });
        }
    };
    const getAllIngredients = () => {
        let ingredientSelection = [];
        const selectionValue = ings && isBase64(ings) && isJson(isBase64(ings), {});
        if (selectionValue) {
            ingredientSelection = selectionValue.ingredientIds;
        }
        dispatch(getAllCategoryIngredientsAction({ query: searchText }))
            .then(({ data: {Ingredients,popularIngredients} }) => {
                setIngredients(Ingredients);
                setPopularIngredients(popularIngredients);
                if (ingredientSelection.length) {
                    let ingredients = [];
                    Ingredients.map(category => {
                        ingredients = ingredients.concat(category.ingredients.filter(ing => ingredientSelection.indexOf(ing.id) !== -1));
                    })
                    dispatch({
                        type: SET_INGREDIENTS,
                        ingredients
                    });
                }
            })
            .catch(err => notification.error({
                message: 'Ingredients',
                description: 'failed to fetch ingredients'
            }));
    };

    const getAllStyleTypes = () => {
        let styleSelection = [];
        const selectionValue = ings && isBase64(ings) && isJson(isBase64(ings), {});
        if (selectionValue) {
            styleSelection = selectionValue.styleIds;
        }
        dispatch(getAllStylesAction({ query: searchText }))
            .then(({ data }) => {
                setStyles(data);
                const styles = [].concat.apply([], data.map(d => d.styles));
                if (styleSelection.length) {
                    dispatch({
                        type: SET_STYLES,
                        styles: [...styles.filter(style => styleSelection.indexOf(style.id) !== -1)]
                    });
                }
            })
            .catch(err => notification.error({
                message: 'Styles',
                description: 'failed to fetch Styles'
            }));
    };

    const updateSearchKeyword = (e) => {
        const searchKeyword = e.target.value;
        setSearchText(searchKeyword)
    };

    const incrementIngredientPopularity = (ingredientId) => {
        incrementIngredientPopularityService(ingredientId)
            .catch((error) => {
                console.log(error)
            })
    };

    useEffect(() => {
        getAllIngredients();
        getAllStyleTypes();
    }, [initData]);
    useEffect(() => {
        if (!searchText) {
            getAllIngredients();
            getAllStyleTypes();
        }
    }, [searchText]);

    const isAllIngredientSelected = () => {
        let count = 0;
        ingredients.map(category => {
            count += category.ingredients.length;
        })
        return appState.selectedIngredients.length === count;
    }

    const menu = <div className="menu-block">
        <Toolbar className="common-form bg-transparent mt-0 text-info title-bar d-flex justify-content-end" style={{padding: '0 25px'}}>
            <Form.Group className="common-field-group search-box mb-0">
                <Form.Control
                    name="query"
                    type="text"
                    placeholder="search"
                    className="common-field custom-border"
                    onChange={updateSearchKeyword}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            getAllIngredients();
                            getAllStyleTypes();
                        }
                    }}
                />
                <IconButton
                    title="search now"
                    className="search-icon"
                    disabled={!searchText}
                    onClick={() => {
                        getAllIngredients();
                        getAllStyleTypes();
                    }}
                    size="small"
                    color="primary"
                >
                    <SearchOutlined />
                </IconButton>
            </Form.Group>
            <Tooltip title="refresh ingredients">
                <IconButton onClick={() => setInitData(!initData)} size="small" color="primary">
                    <RefreshOutlined />
                </IconButton>
            </Tooltip>
        </Toolbar>
        <div className="ingredient-listing">
            <h4 className="text-left mb-3">
                <strong>
                    Ingredients
                </strong>
            </h4>
            <label className="custom-checkbox">
                <span>All Ingredients</span>
                <input
                    checked={isAllIngredientSelected()}
                    onChange={addOrRemoveAllIngredient()}
                    id={`all-ingredients-checkbox`}
                    type="checkbox"
                    name="allIngredients"
                    color="secondary"
                    size="medium"
                />
                <span className="checkmark"/>
            </label>
            <hr className="m-0 mb-3 border border-dark"/>
            <Accordion>
                <div className="w-100">
                    <Accordion.Toggle onClick={() => setOpenCategory(openCategory ? '' : 'popular-ingredients')} as="div" /* className={`${openCategory === 'popular-ingredients' ? 'mb-2' : ''}`} */ eventKey="popular-ingredient-block">
                        <div>Popular Ingredients {openCategory === 'popular-ingredients' ? <ArrowDropUp/> : <ArrowDropDown/>}</div>
                    </Accordion.Toggle>
                    <Accordion.Collapse className="w-100" eventKey="popular-ingredient-block">
                        <div className="w-100">
                            {popularIngredients.map((ingredient) => <label key={ingredient.id} className="custom-checkbox">
                                <span>{ingredient.name}</span>
                                <input
                                    checked={appState.selectedIngredients.map(ing => ing.id).indexOf(ingredient.id) !== -1}
                                    onChange={addOrRemoveIngredient(ingredient)}
                                    id={`${ingredient.name}-${ingredient.id}-checkbox`}
                                    type="checkbox"
                                    className="custom-check-box"
                                    name={ingredient.name}
                                    value={ingredient.id}
                                />
                                <span className="checkmark"/>
                            </label>)}
                        </div>
                    </Accordion.Collapse>
                    <hr className="m-0 mb-2 mt-2 border border-dark"/>
                    <h4 className="text-left mb-2">
                        <strong>
                            Categories
                        </strong>
                    </h4>
                    <hr className="m-0 mb-2 border border-dark"/>
                    {ingredients.map((category) => <Fragment key={category.id}>
                        <Accordion.Toggle onClick={() => setOpenCategory(openCategory ? '' : category.id)} as="div" className={`${openCategory === category.id ? 'mb-2' : ''}`} eventKey={`${category.id}-ingredient-block`}>
                            <div>{category.name} {openCategory === category.id ? <ArrowDropUp/> : <ArrowDropDown/>}</div>
                        </Accordion.Toggle>
                        <Accordion.Collapse className="w-100" eventKey={`${category.id}-ingredient-block`}>
                            <div className="w-100">
                                {category.ingredients.map((ingredient) => <label key={ingredient.id} className="custom-checkbox">
                                    <span>{ingredient.name}</span>
                                    <input
                                        checked={appState.selectedIngredients.map(ing => ing.id).indexOf(ingredient.id) !== -1}
                                        onChange={addOrRemoveIngredient(ingredient)}
                                        id={`${ingredient.name}-${ingredient.id}-checkbox`}
                                        type="checkbox"
                                        className="custom-check-box"
                                        name={ingredient.name}
                                        value={ingredient.id}
                                    />
                                    <span className="checkmark"/>
                                </label>)}
                            </div>
                        </Accordion.Collapse>
                    </Fragment>)}
                </div>
            </Accordion>
            <hr className="m-0 mt-2 mb-2 border border-dark"/>
        </div>
        {styles.map((styleType) => {
            return <div key={styleType.id} className="ingredient-listing mb-2">
                <h4 className="text-left mb-3">
                    <strong>
                        {styleType.name}
                    </strong>
                </h4>
                {styleType.styles.map((style) => <label key={style.id} className="custom-checkbox">
                    <span>{style.name}</span>
                    <input
                        checked={appState.selectedStyles.map(ing => ing.id).indexOf(style.id) !== -1}
                        onChange={addOrRemoveStyle(style)}
                        id={`${style.name}-${style.id}-checkbox`}
                        type="checkbox"
                        className="custom-check-box"
                        name={style.name}
                        value={style.id}
                    />
                    <span className="checkmark"/>
                </label>)}
            </div>
        })}
    </div>;

    return <AppSidebar fixed display="lg">
        <Suspense>
            {menu}
        </Suspense>
    </AppSidebar>
};

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        App: state.app
    }

};


export default connect(mapStateToProps)(withRouter(NavBar));
