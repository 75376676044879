import React, { Suspense } from 'react';
import ReactGA from 'react-ga';
import './App.css';
import './App.scss';
import MainApp from './Containers/App'

function initializeReactGA() {
    ReactGA.initialize('UA-173349734-1');
    ReactGA.pageview('/');
}

initializeReactGA();

function App() {
  return (
    <div className="App">
        <MainApp/>
    </div>
  );
}

export default App;
