import React, { Suspense, useState } from 'react';
import * as router from 'react-router-dom';
import {
  AppHeader,
  AppSidebarToggler,
  AppNavbarBrand
} from '@coreui/react';
import { ExitToApp, Grade } from '@material-ui/icons';
import {
  NavDropdown, Col, Nav as Nav2, Button
} from "react-bootstrap";
import { Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

import { loading, CustomModal } from '../../../Components/commons';
import newLogo from '../../../assets/JerkySeasonLogo.svg';
import './styles.scss';
import { useSelector } from 'react-redux';

export default ({ logout }) => {
  const { user = {}, isLoggedIn } = useSelector(state => state.auth);
  const [contactUs, setContactUs] = useState(false);
  const openCloseContactUs = () => setContactUs(!contactUs);
  return (
    <AppHeader fixed>
      <Suspense fallback={loading()}>
        <AppSidebarToggler className="d-lg-none text-white" display="md" mobile />
        <AppNavbarBrand {...{ to: '/', tag: router.Link }}
          className="d-flex justify-content-center align-items-center ml-2 mr-0"
          full={{ src: newLogo, height: '60%', alt: 'JerkyTrender' }}
        />
        <NavDropdown title="Shop" className="shop-text" id="collasible-nav-dropdown">
          <NavDropdown.Item target="_blank" href="https://sigval.com/how-it-works-2/">Classic Jerky Gun</NavDropdown.Item>
          <NavDropdown.Item target="_blank" href="https://shop.sigval.com/collections/frontpage/products/self-fulfill-sigval-mighty-mat-set-of-3-non-stick-grill-mesh-mats">Beefy Jerky Gun</NavDropdown.Item>
          <NavDropdown.Item target="_blank" href="https://shop.sigval.com/collections/replacement-parts">Smoking Mats</NavDropdown.Item>
          <NavDropdown.Item target="_blank" href="https://shop.sigval.com/collections/replacement-parts">Accesories</NavDropdown.Item>
        </NavDropdown>
        <Nav className="ml-auto h-100 mr-3 top-nav-bar" navbar>
          {!isLoggedIn && <Nav2.Link as={router.Link} className="question-btn" to="/login">
            Login
          </Nav2.Link>}
          {!isLoggedIn && <Nav2.Link as={router.Link} className="question-btn" to="/sign-up">
            Sign Up
          </Nav2.Link>}
          {isLoggedIn && <UncontrolledDropdown nav direction="down" className="h-100">
            <DropdownToggle nav className="h-100 d-flex flex-row justify-content-center align-items-center profile">
              <img
                src={user.imageUrl || '/assets/avatars/default.jpg'}
                className="img-avatar"
                style={{
                  width: '30px',
                  height: '30px',
                  objectFit: 'cover',
                  borderRadius: '50%',
                  objectPosition: '50% 50%',
                  backgroundColor: 'rgba(255, 255, 255, 0.3)'
                }}
                alt={`${user.firstName} ${user.lastName}`}
              />
              <span className="mr-2 nav-bar-username"> {`${user.firstName} ${user.lastName}`}</span>
            </DropdownToggle>
            <DropdownMenu className="border-0">
              <div style={{ width: '300px', height: 'auto', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 0px 5px 3px', backgroundColor: '#061e23' }} className="d-flex flex-column">
                <div className="d-flex justify-content-center flex-column align-items-center" style={{ height: '150px', backgroundColor: '#061e23', color: '#fff' }}>
                  <img src={user.imageUrl || '/assets/avatars/default.jpg'} style={{
                    width: '75px',
                    height: '75px',
                    objectFit: 'cover',
                    borderRadius: '50%',
                    objectPosition: '50% 50%',
                    backgroundColor: 'rgba(255, 255, 255, 0.3)'
                  }} alt={`${user.firstName} ${user.lastName}`} />
                  <div className="w-100 text-center">
                    <h4 style={{color: 'inherit'}}>{`${user.firstName} ${user.lastName}`}</h4>
                    <p className="mb-0">{user.email}</p>
                  </div>
                </div>
                <Col xs={12}>
                  <div className="row p-2">
                    {/* <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.openClosePasswordPop}
                      className="mb-1 mt-1 col-12 text-warning"
                    >
                      <RefreshOutlined /> Change Password
                    </Button> */}
                    <Button
                      color="info"
                      as={router.Link}
                      variant="outline-info"
                      to="/my-favorites"
                      className="mb-1 mt-1 col-12"
                    >
                      <Grade /> My Favorites
                    </Button>
                    <Button
                      color="danger"
                      variant="outline-danger"
                      onClick={logout}
                      className="mb-1 mt-1 col-12"
                    >
                      <ExitToApp /> Logout
                    </Button>
                  </div>
                </Col>
              </div>
            </DropdownMenu>
          </UncontrolledDropdown>}
          <div className="question-btn question-only">
            Question? contact us <span className="here" onClick={openCloseContactUs}>here</span>
          </div>
        </Nav>
      </Suspense>
      <CustomModal
        handleClose={openCloseContactUs}
        open={contactUs}
        viewSide="topRight"
      />
    </AppHeader>
  )
};
