import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

import {
    Route,
    Redirect,
} from 'react-router-dom';

function PublicRoute({ component: Component, ...rest }) {
    const auth = useSelector(state => state.auth);
    const path = rest.location && rest.location.pathname;
    return <Route {...rest} render={(props) => (
        auth.isLoggedIn && (path === '/login' || path === '/sign-up')
            ? <Redirect to='/' /> : <Component {...props} />
    )} />
}

export default PublicRoute;
