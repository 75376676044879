export const drawerWidth = 240;

export const isJson = (value, type) => {
    try {
        return JSON.parse(value) || type;
    } catch (err) {
        return type;
    }
}

export const isBase64 = (value) => {
    try {
        return atob(value);
    } catch (err) {
        return false;
    }
}

export const getQueryParams = (value = '') => {
    const params = {};
    const values = (value.split('?')[1] || '').split('&');
    for(let i = 0; i < values.length; i++) {
        const keyVal = values[i].split('=');
        if (keyVal.length) {
            params[keyVal[0]] = keyVal[1];
        }
    }
    return params;
}