import React, { Component } from 'react';
import { MenuItem, Select, Modal, Backdrop } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import ContactUs from './modals/contactUs';
import DefaultModal from './modals/defaultModal';

const modalClasses = {
  center: 'centered-modal',
  topLeft: 'top-left-modal',
  topRight: 'top-right-modal',
  topCenter: 'top-centered-modal',
  bottomLeft: 'bottom-left-modal',
  bottomRight: 'bottom-right-modal',
  bottomCenter: 'bottom-centered-modal',
  leftCenter: 'left-centered-modal',
  rightCenter: 'right-centered-modal'
};

const modalComponents = {
  default: DefaultModal,
  contact: ContactUs
};

export const loading = () => (<div className="suspense-block d-flex justify-content-center align-items-center">
  <div className="window__refresh">
    <div className="loader" />
  </div>
</div>);

export const MuPager = ({ total, noPagesToDisplay = 4, currentCount, perPageData, onChange, showPageSizeSelection = false }) => {
  const currentPage = Math.ceil((currentCount) / perPageData);
  const totalPage = Math.ceil(total / perPageData);
  return (
    <React.Fragment>
      {showPageSizeSelection && <div className="d-flex justify-content-center align-items-center">
        <span>Rows per page:</span>
      </div>}
      {showPageSizeSelection && <Select
        value={perPageData}
        className="ml-2 pr-2 pl-2"
        style={{
          border: '1px solid rgba(0, 0, 0, 0.5)',
          borderRadius: '3px'
        }}
        onChange={({ target: { value } }) => onChange(0, value)}
      >
        {[5, 10, 25, 50, 100, 250, 500, 1000].map((v, i) => <MenuItem key={i} value={v}>{v}</MenuItem>)}
      </Select>}
      <div className="w-auto ml-2">
        <Pagination
          count={totalPage}
          page={currentPage}
          showFirstButton
          showLastButton
          color="primary"
          size="medium"
          onChange={(e, page) => onChange((page - 1) * perPageData)}
          siblingCount={noPagesToDisplay}
        />
      </div>
    </React.Fragment>
  );
};

export class CustomModal extends Component {
  state = { loading: false };
  render() {
    const { loading } = this.state;
    const { open, handleClose, viewSide = 'center', view = 'default' } = this.props;
    const SelectedComponent = modalComponents[view];
    return (<Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      className={modalClasses[viewSide]}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      children={<SelectedComponent {...this.props}/>}
      disableBackdropClick={loading}
    />);
  }
}
