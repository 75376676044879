import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

import {
    Route,
    Redirect,
} from 'react-router-dom';

function PrivateRoute({ component: Component, ...rest }) {
    const auth = useSelector(state => state.auth);
    return <Route {...rest} render={(props) => (
        auth.isLoggedIn
            ? <Component {...props} />
            : <Redirect to='/' />
    )} />
}

export default PrivateRoute;
