import axios from './axios';

export const getAllCategoryIngredients = (params) => {
    return axios.get('/ingredients/all', { params });
};


export const incrementIngredientPopularityService = (ingredientId) => {
    return axios.get(`/ingredients/inc/popularity/${ingredientId}`);
};
