import {
    GET_ALL_CATEGORY,
    SET_INGREDIENTS,
    SET_STYLES,
    SET_POPULAR_INGREDIENTS
} from '../actions';
import { isJson } from '../../../../constants';

const initState = {
    allIngredients: [],
    selectedIngredients: isJson(localStorage.getItem('selectIngredients'), []),
    selectedStyles: isJson(localStorage.getItem('selectStyles'), []),
    popularIngredients: []
};


const NavbarReducer = (state = initState, action) => {
    if (action.type === GET_ALL_CATEGORY) {
        return {
            ...state,
            allIngredients: action.payload,
        };
    }
    if (action.type === SET_INGREDIENTS) {
        localStorage.setItem('selectIngredients', JSON.stringify(action.ingredients));
        return {
            ...state,
            selectedIngredients: action.ingredients,
        };
    }
    if(action.type === SET_STYLES) {
        localStorage.setItem('selectStyles', JSON.stringify(action.ingredients));
        return {
            ...state,
            selectedStyles: action.styles,
        };
    }
    if(action.type === SET_POPULAR_INGREDIENTS) {
        return {
            ...state,
            popularIngredients: action.data,
        };
    }

    return state;
};

export default NavbarReducer;
