import React, { useState, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Accordion, Button } from 'react-bootstrap';
import {
  Switch,
  Route,
  withRouter
} from 'react-router-dom';
import {
  IconButton,
  withStyles
} from '@material-ui/core';
import { AddTwoTone, RemoveTwoTone } from '@material-ui/icons';

import SideNavbar from './navbar';
import TopBar from './TopBar';
import style from './style';
import './style.scss';
import { loading } from '../../Components/commons';
import { SET_INGREDIENTS } from './store/actions';
import {
  incrementIngredientPopularityService
} from '../../Services/ingredients';
import PublicRoute from '../../Components/PublicRoute';
import { USER_LOGOUT } from '../Login/store/actions';
import AuthCallback from '../authCallbacks';
import PrivateRoute from '../../Components/PrivateRoute';

const Home = React.lazy(() => import('../Home'));
const Login = React.lazy(() => import('../Login'));
const SignUp = React.lazy(() => import('../SignUp'));

function App({ classes, match = {}, history: { location: { pathname } = {} } = {} }) {
  const appState = useSelector(state => state.app);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const logout = () => dispatch({
    type: USER_LOGOUT
  });
  const openCloseIngredientBlock = () => {
    setOpen(!isOpen)
  };

  const incrementIngredientPopularity = (ingredientId) => {
    incrementIngredientPopularityService(ingredientId)
      .catch((error) => {
        console.log(error)
      })
  };

  const addOrRemoveIngredient = (obj, checked) => () => {
    if (checked) {
      dispatch({
        type: SET_INGREDIENTS,
        ingredients: [...appState.selectedIngredients, obj]
      });
      incrementIngredientPopularity(obj.id);
    } else {
      dispatch({
        type: SET_INGREDIENTS,
        ingredients: appState.selectedIngredients.filter(ing => ing.id !== obj.id)
      });
    }
  };

  const popularIngredientsIds = appState.popularIngredients.map(pI => pI.id);
  const selectedIngredientsIds = appState.selectedIngredients.map(sI => sI.id);

  if (['/', '/my-favorites'].indexOf(pathname) === -1) {
    document.querySelector('body').classList.remove('sidebar-lg-show')
    document.querySelector('body').classList.remove('sidebar-fixed')
  } else {
    document.querySelector('body').classList.add('sidebar-lg-show')
    document.querySelector('body').classList.add('sidebar-fixed')
  }

  return (
    <div className="app">
      <TopBar logout={logout}/>
      <div className="app-body">
        {['/', '/my-favorites'].indexOf(pathname) !== -1 && <SideNavbar />}
        <main className="main">
          <Container fluid className="position-relative p-0">
            {['/', '/my-favorites'].indexOf(pathname) !== -1 && <Accordion>
              <div className="app-block">
                <div className="listing-block">
                  <Accordion.Collapse eventKey="ingredient-list-block" className="w-100">
                    <div className="d-flex flex-column w-100">
                      <div className="selected-ingredients caption-label">
                        Try these popular ingredients to narrow down your recipes:
                      </div>
                      <div className="selected-ingredients">
                        {appState.popularIngredients.length && appState.popularIngredients
                          .map(ing => <div key={ing.id} className={`ingredient-tag${selectedIngredientsIds.indexOf(ing.id) !== -1 ? ' selected' : ''}`}>
                              <div className="w-auto d-flex align-items-center">
                                <span className="mr-2">{ing.name}{selectedIngredientsIds.indexOf(ing.id) === -1 ? ` (${ing.recipes || 0})` : ''}</span>
                                <span className="op-icon" onClick={addOrRemoveIngredient(ing, selectedIngredientsIds.indexOf(ing.id) === -1)}>
                                  {selectedIngredientsIds.indexOf(ing.id) === -1 ? <AddTwoTone/> : <RemoveTwoTone/>}
                                </span>
                              </div>
                            </div>)}
                        {appState.selectedIngredients && appState.selectedIngredients.length > 0 &&
                          appState.selectedIngredients
                            .filter(sI => popularIngredientsIds.indexOf(sI.id) === -1)
                            .map(ing => <div key={ing.id} className="ingredient-tag selected">
                              <div className="w-auto d-flex align-items-center">
                                <span className="mr-2">{ing.name}</span>
                                <span className="op-icon" onClick={addOrRemoveIngredient(ing, false)}><RemoveTwoTone/></span>
                              </div>
                            </div>)}
                      </div>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle as={Button} onClick={openCloseIngredientBlock} as="div" eventKey="ingredient-list-block" className="ingredient-list-toggler">
                    {isOpen ? 'Hide' : 'Show'} {!appState.selectedIngredients.length && 'Popular '}Ingredients
                  </Accordion.Toggle>
                </div>
              </div>
            </Accordion>}
            <Row>
              <Suspense fallback={loading()}>
                <Switch>
                  <Route exact path='/' component={Home} />
                  <PrivateRoute exact path='/my-favorites' component={Home} />
                  <PublicRoute exact path="/login" component={Login} />
                  <PublicRoute exact path="/sign-up" component={SignUp} />
                  <PublicRoute exact path="/auth/google/callback" component={AuthCallback} />
                  <PublicRoute exact path="/auth/facebook/callback" component={AuthCallback} />
                </Switch>
              </Suspense>
            </Row>
          </Container>
        </main>
      </div>
    </div>
  );
}

export default withStyles(style)(withRouter(App));
