import {
    login, signUp, socialAuthCallbacks
} from '../../../../Services/auth';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const loginUser = (payload) => (dispatch) => {
    return login(payload)
        .then(({ data: { user = {}, token } = {} }) => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            dispatch({
                type: USER_LOGIN,
                user: user,
                token: token
            });

            return { success: true }
        })
        .catch((err) => {
            throw err.response;
        })
};

export const socialCallbackAction = (path) => (dispatch) => {
    return socialAuthCallbacks(path)
        .then(({ data: { user = {}, token } = {} }) => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            dispatch({
                type: USER_LOGIN,
                user: user,
                token: token
            });

            return { success: true }
        })
        .catch((err) => {
            throw err.response;
        })
};

export const signUpUser = (payload) => (dispatch) => {
    return signUp(payload)
        .then(({ data: { user = {}, token } = {} }) => {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            dispatch({
                type: USER_LOGIN,
                user: user,
                token: token
            });

            return { success: true }
        })
        .catch((err) => {
            throw err.response;
        })
};

export const logOutUser = () => {
    return dispatch => {
        dispatch({
            type: USER_LOGOUT,
        });
    };
};