import {
    getAllCategoryIngredients
} from '../../../../Services/ingredients';
import {
    getAllStyleTypes
} from '../../../../Services/styles';

export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const SET_INGREDIENTS = "SET_INGREDIENTS";
export const SET_STYLES = "SET_STYLES";
export const SET_POPULAR_INGREDIENTS = "SET_POPULAR_INGREDIENTS";

export const getAllCategoryIngredientsAction = (params) => (dispatch) => {
    return getAllCategoryIngredients(params)
        .then(response => {
            dispatch({
                type: SET_POPULAR_INGREDIENTS,
                data: response.data.data.popularIngredients
            });
            return response.data;
        })
        .catch((err) => {
            throw err;
        })
};

export const getAllStylesAction = (params) => ()=> {
  return getAllStyleTypes(params)
      .then(response => response.data)
      .catch((err) => {
          return err;
      })
};
