import React, { useEffect } from 'react';
import { loading as Loading } from '../../Components/commons';
import { useDispatch } from 'react-redux';
import { socialCallbackAction } from '../Login/store/actions';

const AuthCallback = ({ history }) => {

    const dispatch = useDispatch();

    const redirect = () => {
        history.push('/')
    }

    const getSession = () => {
        dispatch(socialCallbackAction(history.location.pathname + history.location.search))
            .then(redirect)
            .catch(redirect);
    }

    useEffect(() => {
        getSession();
    }, []);

    return <div className="login-container">
        <Loading/>
    </div>
}

export default AuthCallback