import React, { Component } from 'react';
import { Card, CardContent, CardHeader, IconButton, Modal, Tooltip, Button } from "@material-ui/core";
import { Close as CloseIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Col } from "react-bootstrap";

export default class DefaultModal extends Component {
    render() {
        const { handleClose } = this.props;
        return <Col tabIndex={-1} xs={10} md={6} lg={6} xl={4}>
            <Card className="h-auto">
                <CardHeader
                    title={<h3>Contact Us</h3>}
                    className="mb-2"
                    style={{ height: '65px', "background-color": '#d14e36' }}
                    action={<React.Fragment>
                        <Tooltip title="Close">
                            <IconButton onClick={handleClose} aria-label="settings">
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </React.Fragment>}
                />
                <CardContent>
                    We love making jerky - and that's why we created JerkySeason.com . If you have any questions or suggestions, we'd love to hear them! Please contact us at our website, <a href="https://sigval.com/contact/" target="_blank">here</a>.
                </CardContent>
            </Card>
        </Col>;
    }
}
